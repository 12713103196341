// Custom.scss
// Option A: Include all of Bootstrap

@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/bootstrap-icons/font/bootstrap-icons.css";
@import "node_modules/ol/ol.css";


@import url(https://fonts.googleapis.com/css?family=Ubuntu:400,300italic,300,400italic,500,500italic,700,700italic);

//*{margin:0; padding:0; font-family:Ubuntu;}

html,body{width:100%; height:100%;}

ol, ul {padding-left: 1rem;}

#mapWrapper{width: 100%;height: 100%;}
#map{width:100%; height:100%;position: relative;}

button.btn-osm{border: 2px solid rgb(0 0 0 / 25%); padding: 0; position: absolute; bottom: 20px; left:410px; z-index: 9999;}
button.btn-osm.hidden{display: none;}
button.btn-osm:hover{opacity: 0.5;}

// #leftPanel{position: absolute; left:0; top:0;z-index: 9999;height: 100%;width:320px;background:none;padding: 130px 0 20px 20px; box-sizing: border-box}
#leftPanel{position: absolute; left:0; top:0;z-index: 9999; width: 390px; height: 100%; overflow-y: auto;}

#leftPanel .gis-heading{font-size: 2rem; padding-left:2rem}

#leftPanel .tab-content{height: 100%;}
#layertree span{float: left; line-height: 28px;width:100%}
#layertree span span{max-width: 280px; display: inherit;}
#layertree span i{float: right; font-size: 24px;}

#leftPanel .panel-footer{text-align:center}
#leftPanel .panel-footer #locationTitle{float:left} 
#leftPanel .panel-footer .vr{margin:0 10px; width:0;}

#layertree li {font-size: 1rem; width: 100%; overflow: hidden;}
#layertree li[data-layerid="osm"]{display: none;}
#layertree li[data-layerid="osmsat"]{display: none;}
// #mainHeader{width: 300px;position: absolute;top:20px; left:20px;}

// .rakovodstvo, .oup{width: 40px;font-size: 20px;position:absolute;left:260px; text-decoration: none!important;}
// .rakovodstvo{top: 15px;}
// .oup{top: 55px;}
// .rakovodstvo .tooltiptext, .oup .tooltiptext{visibility: hidden; background-color:#FFF; color: #777;font-size: 12px;width:180px; text-align: center; border-radius: 0;border:1px solid #777; padding: 5px 0;margin:5px; position: absolute; z-index: 1;}
// .rakovodstvo:hover .tooltiptext, .oup:hover .tooltiptext {visibility: visible;}

// #mainLeftPanel{width: 300px;margin:0;height:100%;box-sizing: border-box;}
// .mainLeftPanelTabs{height: 100%;padding: 1px 1px 100px;box-sizing: border-box;}
// .mainLeftPanelTabContents{height: 100%;overflow:auto;}

// #contentSadarjanie{margin-left: 20px; height: 100%;}
// #contentSadarjanie #layertree{margin-right: 5px;}
// #contentSadarjanie #layertree span{display: block;padding: 5px 0;} 
// #contentSadarjanie #layertree span:hover{cursor: default;} 
// #contentSadarjanie #layertree span i{float: right;}
// #contentSadarjanie #layertree span i:hover{cursor: pointer;} 
// #contentSadarjanie #layertree .parent_li .parent_li ul{padding-left: 20px;}
// #contentLegenda{height: 100%;}

#legendMain{margin: 10px;}
#legendMain .legendItem{padding-bottom: 5px;display: none;}
#legendMain .legendItem.active{display: block;}
#legendMain .legendItem span{display:block;width: 100%; padding-bottom: 3px;}

// .panel-footer{height: 63px;width:298px;box-sizing: border-box;position: absolute;left: 20px;bottom: 20px;margin:0 0 1px 1px;}
// .panel-footer #location{float: right;}
// .panel-footer #scale{float: right;}
// .panel-footer #scale .custom-scale-position{border: 1px solid;border-top: none;padding-left: 5px;}
// .navbar-brand{height: 90px;width: 300px;}
// .navbar-brand img{float: left}
// .navbar-brand span{height: 60px; line-height: 60px;padding-left: 20px;float: left;}

//.mainLeftPanelTabContents .mainLeftPanelTabContent {display:none;}
//.mainLeftPanelTabContents .mainLeftPanelTabContent.active {display:block;}

/*CSS for the OpenLayers map*/
.ol-zoom {left:420px;top:20px;}
.ol-zoom-extent {left:340px;top:70px;}
.ol-control button{background-color:#FFF;color:#777;border:1px solid #ddd;}
.ol-control button:hover{background-color:#e7e7e7;}
.ol-control button:focus{background-color:#e7e7e7;}
.ol-scale-bar.ol-unselectable{margin: 0 0 12px 475px;}

#rightPanel{position:absolute; right:0; top:0; z-index:9999; width:400px;}

#rightPanel .bi-search{padding:5px; font-size: 20px;}
#rightPanel .bi-list{padding:5px 15px 10px 5px; font-size: 24px; display: none;}

#rightPanel .searchInputBox{width:360px; padding:0;}
#rightPanel #resultsPanelWrapper{width:100%; overflow: auto;}
#rightPanel #resultsPanel{width:100%; margin:10px 0;overflow: auto;}
#rightPanel #resultsPanel .searchResult {width:100%; margin:5px 0;}
#rightPanel #resultsPanel .searchResult .searchResultTitle{cursor: pointer; line-height: 30px;}
#rightPanel #resultsPanel .searchResult .searchResultTitle{cursor: pointer; line-height: 30px;}
#rightPanel #resultsPanel .searchResult .searchResultTitle i:before{margin-right:10px}
#rightPanel #resultsPanel .searchResult .searchResultBody{padding:10px}
#rightPanel #resultsPanel .searchResult .searchResultBody .card-inner{text-align: center; margin-top: 10px;}

#rightPanel #resultsPanel .searchResultBody ul{list-style: none;margin: 0; padding:0;}
/*#rightPanel #resultsPanel .searchResultBody ul li{display: block;width: 100%} 
#rightPanel #resultsPanel .searchResultBody ul li .key{float: left}
#rightPanel #resultsPanel .searchResultBody ul li .value{float: right}*/
#rightPanel #resultsPanel .searchResultBody ul li .key{font-weight: bold;}
.searchResultIcon{padding-right: 10px;}

.isLoadingIcon{position: absolute;right:45px;top:5px;z-index:9999;display:none;}
.clearIcon{position: absolute;right:10px;top:5px;font-size:20px; z-index:9999;display:none;text-decoration: none!important;}
.clearIcon:hover{cursor: pointer;} 

#alertWrapper{position: absolute; left: 50%;width:20%;top:20px;display:none;}
.alert{position: relative; left: -50%; width: 100%;z-index: 9999;}

::-webkit-scrollbar
{
  width: 6px;  /* for vertical scrollbars */
  height: 12px; /* for horizontal scrollbars */
}

::-webkit-scrollbar-track
{
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb
{
  background: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width:1024px){
  @viewport{width:1100px;}
  @-o-viewport{width:1100px;}
  @-ms-viewport{width:device-width;}

  #leftPanel, #hrbot, #locationWrapper{visibility: hidden;}
  #leftPanel.active{visibility: visible;}
  
  #rightPanel{width: 100%;}
  #rightPanel .searchInputBox{width: 100%;}
  #rightPanel .searchInputBox input#searchInput{width: 70%;}
  #rightPanel .bi-list{display: block;}
  #rightPanel .bi-search{font-size: 23px; padding: 5px 10px;}

  .ol-scale-bar.ol-unselectable{margin:0 0 20px 0;}
  button.btn-osm{bottom: 80px; left:10px;}
}